@import 'src/Assets/variables';

.plan-card {
  background-color: $white;
  box-shadow: 0px 4px 8px rgba(189, 189, 189, 0.1);
  border-radius: 10px;
  padding: 20px 5% 10px 5%;
  margin-top: 20px;
  position: relative;

  &-heading {
    font-family: 'Poppins-Bold';
    color: $heading;
    margin-right: 40px;
  }

  &-labels {
    display: flex;
    align-items: center;
    margin-top: 10px;
    margin-bottom: 20px;

    &-text {
      color: $heading;
      font-size: 12px;
    }

    &-dot {
      display: inline-block;
      width: 3px;
      height: 3px;
      background-color: #C4C4C4;
      border-radius: 100%;
      margin-left: 10px;
      margin-right: 10px;
    }
  }

  &-bottom {
    &-current {
      border-top: 1px solid #F2F2F2;
      display: flex;
      align-items: center;
      padding-top: 20px;
      padding-bottom: 10px;
      margin-bottom: 10px;

      &-text {
        font-weight: 600;
        font-size: 14px;
        margin-left: 20px;
      }
    }

    &-btn {
      padding-bottom: 10px;
    }
  }

  &-status {
    position: absolute;
    right: 5%;
    top: 20px;
  }
}