@import 'src/Assets/variables';

.transaction-success {
  &-top {
    background-color: $main;
    color: $white;
    text-align: center;
    padding-top: 100px;
    padding-bottom: 150px;
    position: relative;

    &-circle-white {
      position: absolute;
      width: 300px;
      height: 300px;
      background-color: rgba(255, 255, 255, .15);
      left: -40px;
      top: -50px;
      border-radius: 100%;
    }    

    &-heading {
      font-family: 'Poppins-Bold';
      display: block;
      font-size: 36px;
      margin-bottom: 20px;
    }

    &-tick-cover {
      width: 100px;
      height: 100px;
      background-color: rgba(255, 255, 255, 0.3);
      border-radius: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-left: 50%;
      transform: translateX(-50%);
    }

    &-tick-main {
      width: 70px;
      height: 70px;
      background-color: #FFFFFF;
      box-shadow: 0px 8px 8px rgba(196, 196, 196, 0.2);
      border-radius: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &-sub {
      display: block;
      font-family: 'Poppins-Regular';
      font-weight: bold;
      font-size: 18px;
      margin-top: 10px;
      width: 60%;
      margin-left: 50%;
      transform: translateX(-50%);
    }

    &-circle-dark {
      position: absolute;
      width: 200px;
      height: 200px;
      background-color: rgba(162, 218, 255, 0.2);;
      bottom: 0;
      right: 0;
      border-radius: 200px 0 0 0;
      mix-blend-mode: multiply;
    }

    &-dots-empty {
      display: inline-block;
      width: 5px;
      height: 5px;
      border-radius: 100%;
      background-color: transparent;
      border: 1px solid $white;
      position: absolute;

      &#one {
        left: 35%;
        top: 50%;
      }

      &#two {
        left: 69%;
        top: 50%;
      }

      &#three {
        left: 31%;
        top: 38%;
      }

      &#four {
        left: 64%;
        top: 37%;
      }
    }

    &-dots {
      display: inline-block;
      width: 5px;
      height: 5px;
      border-radius: 100%;
      background-color: $white;
      border: 1px solid $white;
      position: absolute;

      &#one {
        left: 35%;
        top: 43%;
      }

      &#two {
        left: 63%;
        top: 46%;
      }

      &#three {
        left: 61%;
        top: 33%;
      }

      &#four {
        left: 38%;
        top: 54%;
      }

    }
  }

  &-content {
    background-color: #FAFAFA;
    border-radius: 12px 12px 0 0;
    position: relative;
    top: -43px;
    padding-top: 30px;
  }

  &-banner {
    width: 90%;
    margin-left: 5%;
    box-shadow: 0px 4px 4px rgba(196, 196, 196, 0.2);
    border-radius: 10px;
    background-color: $white;
    height: 53px;
    color: $secondary;
    display: flex;
    justify-content: center;
    align-items: center;

    &-dot {
      display: inline-block;
      width: 4px;
      height: 4px;
      background-color: $secondary;
      transform: rotate(45deg);

      &.left {
        margin-right: 30px;
      }

      &.right {
        margin-left: 30px;
      }
    }
  }

  &-details {
    &-heading {
      display: block;
      font-family: 'Poppins-Bold';
      padding-left: 5%;
    }
  }

  &-detail {
    display: flex;
    justify-content: space-between;
    font-size: 14px;
    width: 90%;
    margin-left: 5%;
    padding: 10px 0;

    &.bottom {
      border-bottom: 1px solid $ghost;
    }
  }

  &-submit {
    width: 90%;
    margin-left: 5%;
    margin-top: 10px;
    margin-bottom: 20px;
  }
}

#one.fas.fa-plus {
  position: absolute;
  left: 37%;
  top: 35%;
}

#two.fas.fa-plus {
  position: absolute;
  left: 60%;
  top: 50%;
}

#one.fas.fa-star {
  position: absolute;
  font-size: 8px;
  top: 32%;
  left: 34%;
}

#two.fas.fa-star {
  font-size: 6px;
  position: absolute;
  left: 65%;
  top: 57%;
}