.downloader {
  position: fixed;
  width: 100%;
  padding: 0px 20px;
  display: flex;
  flex-wrap: wrap;
  bottom: 0px;
  justify-content: center;
  background: white;
  box-shadow: 0 0 50px #777;
  border-radius: 20px 20px 0 0;

  .option {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    padding: 10px 20px;
    background: #fb9d3c;
    margin-top: 10px;
    cursor: pointer;
    transition: ease-in-out 0.25s;
    color: #fff;
    border-radius: 20px;
    font-size: 10px;
    margin: 10px;

    & img {
      width: 20px;
      margin-right: 5px;
    }
  }
}
