@import 'src/Assets/variables';

.teacher-page {

  // @media only screen and (min-width: $break-point) {
  //   overflow-y: auto;
  // }

  &-calendar {
    border: none !important;
    // margin-left: 50%;
    // transform: translateX(-50%);

    // Month and arrow button styles 
    & .react-calender__navigation {
      background-color: $white;
    }

    // Month text Styles
    & .react-calendar__navigation__label__labelText {
      color: $yearly;
      font-family: 'Poppins-Bold';
      font-weight: bold;
      font-size: 19px;
      border: none;
    }

    // Arrow on sides of month
    & .react-calendar__navigation__arrow {
      font-size: 36px;
      color: #828282;
      background-color: $white !important;
      border: none;
    }
    & .react-calendar__navigation__label {
      background-color: $white !important;
      border: none;
    }

    // Weekday styles 
    & .react-calendar__month-view__weekdays {
      font-family: Nunito Sans;
      font-weight: bold;
      font-size: 12px;
      color: $white;
      // border-bottom: 1px solid #E0E0E0;
      // width: 90%;
      // margin-left: 5%;

      &__weekday {
        margin: 5px;
        padding-top: 5px;
        padding-bottom: 5px;
        background-color: $yearly;
        border-radius: 10px;
      }
      
      // Remove underline from individual weekday
      & abbr[title] {
        text-decoration: none;
        padding-left: 30%;
      }
    }

    // Dates Container
    & .react-calendar__month-view__days {
      width: 100%;
      // margin-left: 5%;
      // margin-top: 20px;
    }

    // Single Date 
    & .react-calendar__month-view__days__day {
      color: $typography;
      font-family: 'Nunito-Regular';
      font-size: 14px;
      font-weight: bold;
      width: 45px;
      height: 45px;
      // border-radius: 100%;
      border: none;
      background-color: $white;

      //Dates of another month
      &--neighboringMonth {
        color: rgb(212, 212, 212);
      }

      // Today's Date
      &.react-calendar__tile--now {
        background-color: $line;
        color: $white;

        // &:hover {
        //   background-color: $button-main;
        //   color: $white;
        // }
      }

      // Selected Date
      &.react-calendar__tile--active {
        background-color: $button-main !important;
        color: $white;
      }
    }
  }

  &-filter {
    height: 40px;
    margin-bottom: 10px;

    p {
      display: inline-block;
      padding-top: 8px;
    }
  }

  &-btn-filter {
    float: right;

    .btn-primary {
      background-color: $yearly !important;
      width: 180px !important;
    }
  }

  &-header {

    padding-left: 5%;
    border-bottom: 4px solid rgba(240, 239, 239, 0.8);
    padding-bottom: 30px;

    &-heading {
      display: block;
      font-family: 'Poppins-Bold';
      color: #4F4F4F;
      font-size: 24px;
    }

    &-details {
      margin-top: 20px;

      &-email {
        font-family: 'Poppins-Regular';
        color: $heading;
        font-weight: bold;
        margin-right: 30px;
      }

      &-phone {
        color: $heading;
        display: block;
      }
    }
  }

  &-content {
    padding: 20px 5%;

    .heading-filter {
      display: inline;
    }

    .live-class {
      width: 100%;
      background-color: #fff;
      padding: 15px;
      margin-top: 25px;
      border-radius: 10px;
      position: relative;

      .heading {
        font-weight: bold;
        font-size: 14px;
      }

      .small {
        font-size: 12px;
        margin-top: 10px;
      }

      .border {
        width: 100%;
        height: 2px;
        background-color: #f2f2f2;
        margin: 20px 0;
      }

      .calendar {
        float: left;
        display: inline;
        font-size: 12px;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;

        img {
          margin-right: 10px;
          height: 15px;
        }
      }

      .kids {
        float: right;
        font-size: 12px;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;

        img {
          margin-right: 10px;
          height: 15px;
        }
      }
    }
  }

  &-btn {
    width: 90%;
    margin-left: 5%;

    &.add {
      margin-top: 20px;
    }

    &.history {
      margin-top: 20px;
    }
  }

  .ongoing {
    padding: 5px 10px;
    color: #fff;
    background: #86DA4E;
    position: absolute;
    font-size: 12px;
    border-radius: 5px;
    right: 10px;
  }
}

.modal-wrapper {
  width: 100%;
  height: 100%;
  position: fixed;
  background: rgba(0, 0, 0, 0.3);
  top: 0;
  left: 0;
  padding: 75px 20px;
  z-index: 999;

  .modal-waiting-list {
    width: auto;
    height: 700px;
    background: white;
    border-radius: 15px;
    -webkit-animation: swing-in-top-fwd 0.5s cubic-bezier(0.175, 0.885, 0.320, 1.275) both;
            animation: swing-in-top-fwd 0.5s cubic-bezier(0.175, 0.885, 0.320, 1.275) both;

    .modal-header{
      background: $yearly;
      border-top-left-radius: 15px;
      border-top-right-radius: 15px;
      color: white;
    }
    
    .modal-body {
      height: 100%;
      position: relative;
      overflow: hidden;

      .waiting-list {
        height: 70%;
        padding-top: 20px;
        overflow-y: scroll;

        ol li {
          margin: 10px 0px;
          font-weight: 600;
        }
      }
    }
  }
}

@-webkit-keyframes swing-in-top-fwd {
  0% {
    -webkit-transform: rotateX(-100deg);
            transform: rotateX(-100deg);
    -webkit-transform-origin: top;
            transform-origin: top;
    opacity: 0;
  }
  100% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg);
    -webkit-transform-origin: top;
            transform-origin: top;
    opacity: 1;
  }
}
@keyframes swing-in-top-fwd {
  0% {
    -webkit-transform: rotateX(-100deg);
            transform: rotateX(-100deg);
    -webkit-transform-origin: top;
            transform-origin: top;
    opacity: 0;
  }
  100% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg);
    -webkit-transform-origin: top;
            transform-origin: top;
    opacity: 1;
  }
}