@import 'src/Assets/variables';

.telkomsel-payment {
  &-input {
    width: 90%;
    margin-left: 5%;

    &-heading {
      font-family: 'Poppins-Bold';
      font-style: normal;
      font-weight: bold;
      font-size: 20px;
      line-height: 30px;
    }

    &-form {

      .invalid-feedback {
        text-align: center;
      }

      .custom-button {
        width: 90%;
        position: absolute;
        bottom: 25px;

        .btn {
          width: 100%;
          padding: 15px 0;
        }
      }

      .wrapper-otp-button {
        width: 90%;
        position: absolute;
        bottom: 0;
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;

        span {
          margin: auto;
          margin-bottom: 50px;
        }

        .custom-button {
          width: 90%;
          position: relative !important;
          bottom: 25px;
          margin: auto;

          .btn {
            width: 100%;
            padding: 15px 0;
          }
        }
      }
      
      .wrapper-otp {
        position: relative;
        text-align: center;
        color: #FF5050;
      }

      .wrapper {
        position: relative;
        text-align: center;
        color: #FF5050;

        img {
          position: absolute;
          top: 32.5px;
          right: 10px;
          height: 22px;
        }

        span {
          font-size: 10px;
        }

        .form-group:before {
          content: '+62';
          width: 2em;
          position: absolute;
          left: 0;
          top: 32.5px;
          color: #aaa;
          padding: 1px 10px 0px 10px;
          font-size: 14px;
        }

        .form-input .form-group input {
          padding-left: 3em;
        }
      }
    }
  }

}