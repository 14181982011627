@import 'src/Assets/variables';

.edit-profile {
  &-input-label {
    display: block;
    margin-top: 20px;
    margin-left: 5%;
    font-family: 'Poppins-Regular';
    font-weight: bold;
    font-size: 14px;
    line-height: 10px;

    &-img {
      margin-right: 10px;
    }
  }

  &-input {
    width: 90%;
    margin-left: 5%;
    margin-top: -15px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    flex: 1 1 0%;
    justify-content: space-between;

    &.start {
      .form-input {
        width: 60%;

        .form-group {
          width: 100%;
        }
      }
    }

    &.verification {
      .form-input {
        width: 40%;

        .form-group {
          width: 100%;
        }
      }
    }

    .form-input {
      display: flex;
      flex-grow: 1;
      width: 90%;

      .form-group {
        width: 100%;
      }
    }

    .verification {
      background: $monthly;
      color: $white;
      max-height: 40px;
      padding: 5px 15px;
      margin-top: 26px;
      border-radius: 10px;
      border: 0px;
      margin-left: 10px;

      &.start {
        background: $yearly;
      }
    }
  }

  &-banner {
    width: 90%;
    margin-left: 5%;
    box-shadow: 0px 4px 8px rgba(189, 189, 189, 0.1);
    border-radius: 10px;
    padding: 20px 5%;
    margin-top: 40px;
    position: relative;

    &.gold {
      background-color: $secondary;
      color: $white;
    }

    &.grey {
      background-color: $ghost;
      color: #828282;
    }

    &-heading {
      display: block;
      font-family: 'Poppins-Regular';
      font-weight: bold;
    }

    &-sub {
      font-size: 14px;
      margin-top: 10px;
    }

    &-btn {
      position: absolute;
      right: 5%;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  &-submit {
    width: 90%;
    margin-left: 5%;
    margin-top: 250px;

    &-btn {
      margin-bottom: 20px;
    }
  }
}

.disabled {
  background: #e9ecef !important;
}
