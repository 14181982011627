@import 'src/Assets/variables'; 

.lang {
    background-color: $main;
    right: 0px;
    position: absolute;
    z-index: 1;
    margin: 15px;

    &-img {
      margin: 5px;
      width: 40px;
      cursor: pointer;
      
    }

    .selected {
      border: 2px solid blue;
      border-radius: 50%;
    }
}