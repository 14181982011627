@import 'src/Assets/variables';

.billing-history {
  &-month {
    padding-left: 5%;
    color: $main-txt;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  &-billing {
    background-color: $white;
    border-bottom: 1px solid #F2F2F2;
    padding: 20px 5%;

    &-date {
      font-size: 14px;
    }

    &-content {
      display: flex;
      justify-content: space-between;
      font-family: 'Poppins-Bold';
      font-size: 14px;
      margin-top: 10px;
    }

    &-reference {
      display: flex;
      justify-content: space-between;
      font-size: 14px;
      font-weight: 800;
      margin-top: 10px;
    }
  }

  &-no-payment {
    width: 70%;
    margin-left: 15%;
    background-color: #ffffff;
    border-radius: 8px;
    text-align: center;
    margin-top: 50px;
    padding: 60px 0;

    &-img {
      width: 50%;
    }

    &-text {
      display: block;
      font-family: 'Poppins-Bold';
      font-size: 20px;
      margin-top: 20px;
    }
  }
}