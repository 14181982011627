@import 'src/Assets/variables';

.centering {
  padding: 25vh 0;
}

.certificate-page {
  width: 100%;
  position: relative;

  & .background {
    width: 98%;
    margin: 1% 1% 0% 1%;
  }

  & .content {
    position: absolute;
    top: 0;
    text-align: center;
    width: 98%;
    font-family: 'Gotham-Bold';

    & .name {
      margin: 0;
      font-weight: bold;
      color: #fb9d3c;
      text-transform: uppercase;

      &.weekly {
        margin-top: 29vw;
        font-size: 3vw;
      }

      &.period {
        margin-top: 40.5vw;
        font-size: 3vw;
      }

      &.league {
        margin-top: 30vw;
        font-size: 3vw;
      }

      &.period0 {
        display: none;
      }
    }

    & .periode {
      color: #fff;
      font-family: Cartwheel;
      font-size: 1.15vw;
      margin-top: 19.7vw;
    }
  }
}
