@import 'src/Assets/variables';

.login-page {
  padding-bottom: 40px;

  .paragraph {
    margin: 0 25px;
    margin-top: 30px;
    text-align: center;

    span {
      color: $yearly;
    }
  }

  .renderField {
    margin: 0 25px;
  }

  &-submit {
    // position: absolute;
    bottom: 15px;
  }

  .verifyField {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;

    span {
      max-width: 50%;
    }

    .custom-button {
      max-width: 180px;
    }

    button {
      background-color: $yearly !important;
    }
  }
}
