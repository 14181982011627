@import 'src/Assets/variables';

.label-container {
  display: inline-block;
  color: #FFFFFF;
  background-color: $line;
  font-size: 12px;
  border-radius: 5px;
  padding: 7px 10px;

  &.FCCCE4 {
    background-color: $monthly;
  }

  &.C0A4E8{
    background-color: $weekly;
  }

  &.E5B174{
    background-color: $yearly;
  }

  &.EBB4A3{
    background-color: $days;
  }

}