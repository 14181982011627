.loading-component {
  display: flex;
  width: 100%;
  min-height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
  justify-content: center;
  align-items: center;

  &-image {
    display: flex;
    width: 200px;
    height: 200px;
    background: url('../../Assets/Images/loading-image.png') no-repeat center;
    background-size: contain;
    position: relative;
    justify-content: center;

    &-text {
      position: absolute;
      bottom: 22px;
      z-index: 9999;
      text-align: center;

      & span {
        font-size: 64px;
      }
    }
  }
}
