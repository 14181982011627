@import 'src/Assets/variables'; 

.login-page {
  padding-bottom: 40px;

  &-header {
    background-color: $main;
    height: 40vh;
    border-radius: 0px 0px 50% 50%;
    text-align: center;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    &-heading {
      font-family: 'Poppins-Bold';
      font-size: 50px;
      color: $white;
      margin-bottom: 80px;
    }

    &-img {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      bottom: -20px;
    }
  }

  &-inputs {
    margin-top: 30px;
  }

  &-input {
    border: none;
    background-color: transparent;
    color: $typography;
    font-size: 14px;
    font-family: 'Nunito-Regular', serif;
    width: stretch;
  
    &::placeholder {
      color: rgba(79, 79, 79, 0.5);
      font-size: 12px;
      letter-spacing: 0;
    }

    &:focus, &:active {
      outline: none;
    }

    &.password {
      color: $secondary;
      font-size: 20px;
      letter-spacing: 3px;
    }

    &-container {
      height: 40px;
      width: 90%;
      margin-left: 5%;
      border-radius: 10px;
      border: 1px solid $line;
      display: flex;
      align-items: center;
      margin-top: 20px;
      padding-left: 5%;

      &.errored {
        border: 2px solid #dc3545;
      } 
    }

    &-img {
      margin-right: 10px;

      &.errored {
        filter: brightness(0) saturate(100%) invert(27%) sepia(78%) saturate(2162%) hue-rotate(334deg) brightness(90%) contrast(91%);
      }
    }
  }

  &-helper-text {
    width: 90%;
    margin-left: 5%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 20px;

    &-forgot {
      color: $secondary;
      cursor: pointer;
    }
  }

  &-submit {
    width: 90%;
    margin-left: 5%;
    margin-top: 20px;
    border-radius: 8px;
  }

  // Check Styles
  &-check {
    position: relative;
    cursor: pointer;
    padding: 10px 0 10px 40px;
    color: $typography;

    &-input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0;
    }

    &-checkmark {
      position: absolute;
      top: 12px;
      left: 10px;
      height: 20px;
      width: 20px;
      background-color: $white;
      border: 1px solid #BDBDBD;
      border-radius: 4px;

      &:after {
        content: "";
        content: "";
        position: absolute;
        display: none;
        left: 7px;
        top: 1px;
        width: 6px;
        height: 15px;
        border: solid #FFFFFF;
        border-width: 0 3px 3px 0;
        transform: rotate(45deg);
      }
    }

    &-input:checked ~ &-checkmark {
      background-color: $button-main;
      border: $button-main;
    }

    &-input:checked ~ &-checkmark:after {
      display: block;
    }

  }

  &-validation-error {
    color: #dc3545;
    font-size: 12px;
    margin-left: 5%;
  }

  &-error {
    width: 90%;
    margin-left: 5%;
    background-color: #FEF6F6;
    border: 1px solid #E0B4B4;
    color: #912D2B;
    border-radius: 2px;
    padding: 5px 0;
    font-size: 14px;
    padding-left: 5%;
    font-family: 'Poppins-Regular';

  }

}