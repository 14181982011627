@import 'src/Assets/variables';

.confirm-modal {
  background: rgba($color: #000000, $alpha: 0.7);
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  z-index: 9999;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;

  &-content {
    width: 80%;
    background: #fff;
    margin: auto;
    text-align: center;
    border-radius: 15px;
    position: relative;
	-webkit-animation: swing-in-top-fwd 0.5s cubic-bezier(0.175, 0.885, 0.320, 1.275) both;
	        animation: swing-in-top-fwd 0.5s cubic-bezier(0.175, 0.885, 0.320, 1.275) both;

    h2 {
      font-weight: bold;
      line-height: 1.5;
    }

    &-logo {
      height: 145px;
      position: absolute;
      transform: translate(-50%);
      top: -132px;
    }

    .modal-header {
        background-color: $main-txt;
        justify-content: center;
        color: #fff;
        font-weight: bold;
        font-size: 35px;
        border-radius: 15px 15px 0px 0px;
    }

    .modal-subscription-button {
      position: relative;
      margin-top: 30px;
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;
      width: 100%;

      & .custom-button {
        display: flex;
        width: 100%;
        margin-top: 15px;

        & .btn {
          width: 100%;
          padding: 15px 0 15px 0;
        }

        &.secondary {
          background: #fff;
          border: 1px solid $main-txt;
        }
      }
    }
  }
}

@-webkit-keyframes swing-in-top-fwd {
  0% {
    -webkit-transform: rotateX(-100deg);
            transform: rotateX(-100deg);
    -webkit-transform-origin: top;
            transform-origin: top;
    opacity: 0;
  }
  100% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg);
    -webkit-transform-origin: top;
            transform-origin: top;
    opacity: 1;
  }
}
@keyframes swing-in-top-fwd {
  0% {
    -webkit-transform: rotateX(-100deg);
            transform: rotateX(-100deg);
    -webkit-transform-origin: top;
            transform-origin: top;
    opacity: 0;
  }
  100% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg);
    -webkit-transform-origin: top;
            transform-origin: top;
    opacity: 1;
  }
}