@import '~bootstrap/scss/bootstrap';
@import 'src/Assets/variables';

@font-face {
  font-family: 'Cartwheel';
  src: url('../src/Assets/Fonts/Cartwheel.otf');
}

@font-face {
  font-family: 'Poppins-Regular';
  src: url('../src/Assets/Fonts/Poppins-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Poppins-SemiBold';
  src: url('../src/Assets/Fonts/Poppins-SemiBold.ttf') format('truetype');
}

@font-face {
  font-family: 'Poppins-Bold';
  src: url('../src/Assets/Fonts/Poppins-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'Nunito-Regular';
  src: url('../src/Assets/Fonts/Nunito-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Gotham-Bold';
  src: url('../src/Assets/Fonts/Gotham-Bold.otf') format('truetype');
}

body {
  font-family: 'Nunito-Regular', serif;
  font-weight: normal;
  font-style: normal;
  color: #4f4f4f;
  margin: 0;
  height: 100%;
  background-color: #ffffff;
}

.bold-text {
  font-weight: bold;
}

.page-container {
  background-color: #ffffff;
  min-height: 800px;

  @media only screen and (min-width: $break-point) {
    width: 600px;
    margin-left: 50%;
    transform: translateX(-50%);
  }

  @media only screen and (max-width: $break-point) {
    width: 100%;
    margin-top: 0;
    box-shadow: none;
  }
}

.body-content {
  background: #fafafa;
  border-radius: 15px 15px 0px 0px;
  padding: 20px 0 20px 0;
  position: relative;
  z-index: 2;
  top: -13px;
  min-height: calc(800px - #{$navbar-height});

  &-heading {
    font-family: 'Poppins-Bold';
    font-size: 24px;
    padding-left: 5%;

    &-back-arrow {
      margin-right: 20px;
      font-weight: 500;
      font-size: 20px;
      cursor: pointer;
    }
  }
}

.bold-text {
  font-weight: bold;
}
