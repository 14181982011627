$white: #FFFFFF;

$main: #A2DAF1;
$main-txt: #64B3D4;
$secondary: #E3B26A;
$typography: #636363;
$line: #BDBDBD;
$ghost: #E0E0E0;
$button-main: #83CDEC;

$heading: #4F4F4F;

$navbar-height: 100px;

$break-point: 600px;

$yearly: #E5B174;
$monthly: #4FCCCE;
$weekly: #C0A4E8;
$days: #EBB4A3;
