@import 'src/Assets/variables';

.upgrade-page {
  &-top {
    width: 90%;
    margin-left: 5%;
    background-color: $white;
    box-shadow: 0px 4px 8px rgba(189, 189, 189, 0.1);
    border-radius: 20px;
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 5%;
    margin-top: 20px;

    &-name {
      font-family: 'Poppins-Bold';
      font-weight: bold;
      color: #4f4f4f;
    }

    &-labels {
      color: #4f4f4f;
      font-size: 14px;
      display: flex;
      align-items: center;
    }
  }

  &-subscription {
    width: 90%;
    margin-left: 5%;
    overflow: hidden;

    &-heading {
      font-family: 'Poppins-Bold';
      font-style: normal;
      font-weight: bold;
      font-size: 20px;
      line-height: 30px;
    }

    &-type {
      display: flex;
      margin-top: 20px;
      flex-direction: row;
      overflow-x: scroll;

      &-box {
        background: #ffffff;
        box-shadow: 0px 4px 8px rgba(189, 189, 189, 0.25);
        border: solid 1px #64b3d4;
        border-radius: 10px;
        color: #4f4f4f;
        margin-right: 10px;
        padding: 20px 20px 20px 20px;
        font-weight: bold;
        cursor: pointer;
        white-space: nowrap;
        min-width: 40%;
        text-align: center;

        &.selected {
          background: #64b3d4;
          color: #ffffff;
        }
      }
    }

    &-premium {
      margin-top: 20px;

      &-plan {
        box-shadow: 0px 4px 8px rgba(189, 189, 189, 0.1);
        border-radius: 10px;

        .heading {
          width: 90%;
          margin-left: 5%;
          padding: 10px 0;
          display: flex;
          justify-content: space-between;
          font-family: 'Poppins-Regular';
          font-weight: bold;
          font-size: 16px;

          span {
            padding: 10px 0;
          }

          .strikeout {
            position: relative;
            margin-right: 25px;
          }

          .strikeout::after {
            content: '';
            left: -10%;
            width: 120%;
            margin-top: calc(0.175em / 2 * -1);
            position: absolute;
            right: 0;
            top: 50%;
            transform: rotate(-5deg);
          }
        }

        .points {
          width: 90%;
          margin-left: 5%;
          padding: 10px 0;
          padding-bottom: 20px;
        }

        .point {
          display: flex;
          align-items: center;
          font-size: 14px;
          margin-top: 10px;

          .tick {
            background-color: #ffffff;
            stroke: #e5b174;
            width: 20px;
            height: 20px;
            border-radius: 100%;
            color: $white;
            font-size: 16px;
            font-weight: bold;
            padding-top: 2px;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-right: 10px;
          }
        }

        &.FFFFFF {
          background-color: #ffffff;
          color: $heading;

          .heading {
            border-bottom: 2px solid #f2f2f2;
            color: $heading;

            .strikeout::after {
              border-bottom: 0.175em solid $heading;
            }
          }

          .point {
            .tick {
              background-color: $main-txt;
              stroke: #ffffff;
            }
          }
        }

        &.E5B174 {
          background-color: #e5b174;
          color: $white;

          .heading {
            border-bottom: 2px solid #f2f2f2;
            color: $white;

            .strikeout::after {
              border-bottom: 0.175em solid $white;
            }
          }

          .point {
            .tick {
              background-color: #ffffff;
              stroke: #e5b174;
            }
          }
        }

        &.FCCCE4 {
          background-color: #4fccce;
          color: $white;

          .heading {
            border-bottom: 2px solid #f2f2f2;
            color: $white;

            .strikeout::after {
              border-bottom: 0.175em solid $white;
            }
          }

          .point {
            .tick {
              background-color: #ffffff;
              stroke: #4fccce;
            }
          }
        }

        &.C0A4E8 {
          background-color: #c0a4e8;
          color: $white;

          .heading {
            border-bottom: 2px solid #f2f2f2;
            color: $white;

            .strikeout::after {
              border-bottom: 0.175em solid $white;
            }
          }

          .point {
            .tick {
              background-color: #ffffff;
              stroke: #c0a4e8;
            }
          }
        }

        &.EBB4A3 {
          background-color: #ebb4a3;
          color: $white;

          .heading {
            border-bottom: 2px solid #f2f2f2;
            color: $white;

            .strikeout::after {
              border-bottom: 0.175em solid $white;
            }
          }

          .point {
            .tick {
              background-color: #ffffff;
              stroke: #ebb4a3;
            }
          }
        }
      }
    }
  }

  &-voucher {
    width: 90%;
    margin-left: 5%;

    &-heading {
      font-family: 'Poppins-Bold';
      font-style: normal;
      font-weight: bold;
      font-size: 20px;
      line-height: 30px;
    }

    &-form {
      .invalid-feedback {
        text-align: center;
      }
    }
  }

  &-payment {
    border-top: 4px solid rgba(240, 239, 239, 0.8);
    margin-top: 30px;
    padding-top: 10px;

    &.parent {
      margin-bottom: 50px;
    }

    &-method {
      background-color: $white;
      box-shadow: 0px 4px 8px rgba(189, 189, 189, 0.1);
      border-radius: 10px;
      padding: 0px 5%;
      margin-top: 10px;
      width: 90%;
      margin-left: 5%;
      height: 60px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      cursor: pointer;

      & .selected {
        color: $monthly;
      }

      &-img {
        height: 30px;
      }

      &-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;

        &-item {
          display: flex;
          height: 30px;
          padding: 5px;
          border: 1px solid #ddd;
          margin-right: 10px;
          border-radius: 5px;
          line-height: 1;
          font-weight: bold;
          color: #777;
        }

        &-svg {
          width: 18px;
          height: 18px;
          display: inline-block;

          svg {
            margin-top: -10px;
          }

          svg path {
            transition: 0.2s;
          }
        }
      }

      &-toggle {
        width: 18px;
        height: 18px;
        border-radius: 100%;
        display: inline-block;
        border: 1px solid $line;

        &-active {
          background-color: $main-txt;
          width: 12px;
          height: 12px;
          border-radius: 100%;
          display: inline-block;

          &-outer {
            width: 18px;
            height: 18px;
            background-color: rgba(164, 218, 241, 0.5);
            border-radius: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }
      }
    }
  }

  &-submit {
    width: 90%;
    margin-left: 5%;
    margin-top: 50px;
  }
}

.border-section {
  margin-top: 20px;
  margin-bottom: 20px;
  background-color: rgba(240, 239, 239, 0.8);
  height: 4px;
}

.parent {
  margin-bottom: 30px;
}