@import 'src/Assets/variables';

.invalid-feedback.active {
  display: block !important;
  text-align: center;
}

.alternative {
  color: #E3B26A !important;
}

.form-group.phone-number:before {
  content: '+62';
  width: 2em;
  position: absolute;
  left: 0;
  top: 68px;
  color: #aaa;
  padding: 1px 10px 0px 10px;
  font-size: 14px;
}

.form-input .form-group.phone-number input {
  padding-left: 3em;
}

.form-input {
  position: relative;

  &-control {
    font-size: 14px;
    height: 50px;
    padding: 0 15px;
    border-radius: 10px !important;
    border: 1px solid $line !important;
    height: 40px !important;
    background-color: #ffffff !important;
    font-size: 14px !important;

    &:focus {
      outline: 0;
      box-shadow: none;
      border: 2px solid $main;
    }

    &.is-invalid {
      border: 2px solid #dc3545;
      box-shadow: none;

      &:focus {
        outline: 0;
        box-shadow: none;
      }
    }

    &::placeholder {
      color: #BDBDBD !important;
    }
  }

  &-label {
    font-size: 12px;
    text-transform: uppercase;
    font-weight: bold;
  }

  &-custom-label {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-bottom: 10px;
    font-weight: bold;

    &-wrapper-icon {
      width: 25px;
      height: 25px;
      margin-right: 15px;

      img {
        width: 100%;
      }
    }
  }
}
