@import 'src/Assets/variables';

.account-page {

  // @media only screen and (min-width: $break-point) {
  //   overflow-y: auto;
  // }

  &-header {

    padding-left: 5%;
    border-bottom: 4px solid rgba(240, 239, 239, 0.8);
    padding-bottom: 30px;

    &-heading {
      display: block;
      font-family: 'Poppins-Bold';
      color: #4F4F4F;
      font-size: 24px;
    }

    &-details {
      margin-top: 20px;

      &-email {
        font-family: 'Poppins-Regular';
        color: $heading;
        font-weight: bold;
        margin-right: 30px;
      }

      &-phone {
        color: $heading;
        display: block;
      }
    }
  }

  &-child-plan {
    padding: 20px 5% 0 5%;

    &-heading {
      display: block;
      color: $heading;
      font-family: 'Poppins-Bold';
      font-size: 26px;
    }
  }

  &-btn {
    width: 90%;
    margin-left: 5%;

    &.add {
      margin-top: 20px;
    }

    &.history {
      margin-top: 20px;
    }
  }

}