@import 'src/Assets/variables';

.page-container {
  background-color: #fafafa !important;
}

.signup-page {

  &-top {
    width: 90%;
    margin-left: 5%;
    background-color: $white;
    box-shadow: 0px 4px 8px rgba(189, 189, 189, 0.1);
    border-radius: 20px;
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 5%;
    margin-top: 20px;

    &-name {
      font-family: 'Poppins-Bold';
      font-weight: bold;
      color: #4F4F4F;
    }

    &-labels {
      color: #4f4f4f;
      font-size: 14px;
      display: flex;
      align-items: center;
    }
  }

  &-form {
    width: 90%;
    margin-left: 5%;

    &-heading {
      font-family: 'Poppins-Bold';
      font-style: normal;
      font-weight: bold;
      font-size: 20px;
      line-height: 30px;
    }

    &-heading-child {
      font-family: 'Poppins-Bold';
      font-style: normal;
      font-weight: bold;
      font-size: 20px;
      line-height: 30px;
      margin-top: 20px;
    }

    &-input .form-group {
      margin-bottom: 0;
    }
  }

  &-subscription {
    width: 90%;
    margin-left: 5%;
    overflow: hidden;

    &-heading {
      font-family: 'Poppins-Bold';
      font-style: normal;
      font-weight: bold;
      font-size: 20px;
      line-height: 30px;
    }

    &-type {
      display: flex;
      margin-top: 20px;
      flex-direction: row;
      overflow-x: scroll;

      &-box {
        background: #FFFFFF;
        box-shadow: 0px 4px 8px rgba(189, 189, 189, 0.25);
        border: solid 1px #64B3D4;
        border-radius: 10px;
        color: #4F4F4F;
        margin-right: 10px;
        padding: 20px 40px 20px 40px;
        font-weight: bold;
        cursor: pointer;
        white-space: nowrap;
        min-width: 40%;
        text-align: center;

        &.selected {
          background: #64B3D4;
          color: #ffffff;
        }
      }
    }

    &-premium {
      margin-top: 20px;
  
      &-yearly {
        box-shadow: 0px 4px 8px rgba(189, 189, 189, 0.1);
        border-radius: 10px;
        background-color: #E5B174;
  
        &-heading {
          width: 90%;
          margin-left: 5%;
          padding: 10px 0;
          border-bottom: 2px solid #F2F2F2;
          color: $white;
          display: flex;
          justify-content: space-between;
          font-family: 'Poppins-Regular';
          font-weight: bold;
          font-size: 16px;

          span {
            padding: 10px 0;
          }
        }
    
        &-points {
          width: 90%;
          margin-left: 5%;
          padding: 10px 0;
          padding-bottom: 20px;
        }
    
        &-point {
          display: flex;
          align-items: center;
          color: $white;
          font-size: 14px;
          margin-top: 10px;
    
          &-tick {
            background-color: #ffffff;
            stroke: #E5B174;
            width: 20px;
            height: 20px;
            border-radius: 100%;
            color: $white;
            font-size: 16px;
            font-weight: bold;
            padding-top: 2px;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-right: 10px;
          }
        }
      }

      &-monthly {
        box-shadow: 0px 4px 8px rgba(189, 189, 189, 0.1);
        border-radius: 10px;
        background-color: #4FCCCE;
        color: #FFFFFF;
  
        &-heading {
          width: 90%;
          margin-left: 5%;
          padding: 10px 0;
          border-bottom: 2px solid #F2F2F2;
          color: $white;
          display: flex;
          justify-content: space-between;
          font-family: 'Poppins-Regular';
          font-weight: bold;
          font-size: 16px;

          span {
            padding: 10px 0;
          }
        }
    
        &-points {
          width: 90%;
          margin-left: 5%;
          padding: 10px 0;
          padding-bottom: 20px;
        }
    
        &-point {
          display: flex;
          align-items: center;
          color: $white;
          font-size: 14px;
          margin-top: 10px;
    
          &-tick {
            background-color: #ffffff;
            stroke: #4FCCCE;
            width: 20px;
            height: 20px;
            border-radius: 100%;
            color: $white;
            font-size: 16px;
            font-weight: bold;
            padding-top: 2px;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-right: 10px;
          }
        }
      }

      &-weekly {
        box-shadow: 0px 4px 8px rgba(189, 189, 189, 0.1);
        border-radius: 10px;
        background-color: #C0A4E8;
        color: #FFFFFF;
  
        &-heading {
          width: 90%;
          margin-left: 5%;
          padding: 10px 0;
          border-bottom: 2px solid #F2F2F2;
          color: $white;
          display: flex;
          justify-content: space-between;
          font-family: 'Poppins-Regular';
          font-weight: bold;
          font-size: 16px;

          span {
            padding: 10px 0;
          }
        }
    
        &-points {
          width: 90%;
          margin-left: 5%;
          padding: 10px 0;
          padding-bottom: 20px;
        }
    
        &-point {
          display: flex;
          align-items: center;
          color: $white;
          font-size: 14px;
          margin-top: 10px;
    
          &-tick {
            background-color: #ffffff;
            stroke: #C0A4E8;
            width: 20px;
            height: 20px;
            border-radius: 100%;
            color: $white;
            font-size: 16px;
            font-weight: bold;
            padding-top: 2px;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-right: 10px;
          }
        }
      }
  
      &-3-days{
        box-shadow: 0px 4px 8px rgba(189, 189, 189, 0.1);
        border-radius: 10px;
        background-color: #EBB4A3;
        color: #FFFFFF;
  
        &-heading {
          width: 90%;
          margin-left: 5%;
          padding: 10px 0;
          border-bottom: 2px solid #F2F2F2;
          color: $white;
          display: flex;
          justify-content: space-between;
          font-family: 'Poppins-Regular';
          font-weight: bold;
          font-size: 16px;

          span {
            padding: 10px 0;
          }
        }
    
        &-points {
          width: 90%;
          margin-left: 5%;
          padding: 10px 0;
          padding-bottom: 20px;
        }
    
        &-point {
          display: flex;
          align-items: center;
          color: $white;
          font-size: 14px;
          margin-top: 10px;
    
          &-tick {
            background-color: #ffffff;
            stroke: #EBB4A3;
            width: 20px;
            height: 20px;
            border-radius: 100%;
            color: $white;
            font-size: 16px;
            font-weight: bold;
            padding-top: 2px;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-right: 10px;
          }
        }
      }
  
      &-free {
        box-shadow: 0px 4px 8px rgba(189, 189, 189, 0.1);
        border-radius: 10px;
        background-color: #ffffff;
        color: $main-txt;
  
        &-heading {
          width: 90%;
          margin-left: 5%;
          padding: 10px 0;
          border-bottom: 2px solid #F2F2F2;
          color: #4F4F4F;
          display: flex;
          justify-content: space-between;
          font-family: 'Poppins-Regular';
          font-weight: bold;
          font-size: 16px;

          span {
            padding: 10px 0;
          }
        }
    
        &-points {
          width: 90%;
          margin-left: 5%;
          padding: 10px 0;
          padding-bottom: 20px;
        }
    
        &-point {
          display: flex;
          align-items: center;
          color: #4F4F4F;
          font-size: 14px;
          margin-top: 10px;
    
          &-tick {
            background-color: $main-txt;
            stroke: #ffffff;
            width: 20px;
            height: 20px;
            border-radius: 100%;
            color: $white;
            font-size: 16px;
            font-weight: bold;
            padding-top: 2px;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-right: 10px;
          }
        }
      }
    }
  }

  &-voucher {
    width: 90%;
    margin-left: 5%;

    &-heading {
      font-family: 'Poppins-Bold';
      font-style: normal;
      font-weight: bold;
      font-size: 20px;
      line-height: 30px;
    }

    &-form {
      .invalid-feedback {
        text-align: center;
      }
    }
  }

  &-payment {
    border-top: 4px solid rgba(240, 239, 239, 0.8);
    margin-top: 30px;
    padding-top: 10px;

    &-method {
      background-color: $white;
      box-shadow: 0px 4px 8px rgba(189, 189, 189, 0.1);
      border-radius: 10px;
      padding: 0px 5%;
      margin-top: 10px;
      width: 90%;
      margin-left: 5%;
      height: 60px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      cursor: pointer;

      &-img {
        height: 100%;
      }

      &-toggle {
        width: 18px;
        height: 18px;
        border-radius: 100%;
        display: inline-block;
        border: 1px solid $line;

        &-active {
          background-color: $main-txt;
          width: 12px;
          height: 12px;
          border-radius: 100%;
          display: inline-block;
  
          &-outer {
            width: 18px;
            height: 18px;
            background-color: rgba(164, 218, 241, 0.5);
            border-radius: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }
      }
    }
  }

  &-submit {
    position: absolute;
    bottom: 15px;
    width: 100%;
    padding: 25px;
  }
}

.border-section {
  margin-top:20px;
  margin-bottom: 20px;
  background-color: rgba(240, 239, 239, 0.8);
  height: 4px;
}

@media (max-width: 450px) {
  .signup-page-subscription-type {
    flex-direction: column;
  }
  .signup-page-subscription-type-box-selected {
    margin-bottom: 10px;
  }
  .signup-page-subscription-type-box {
    margin-bottom: 10px;
  }
}

.avatar-wrapper {
  width: 90%;
  margin: 5%;

  .custom-label {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-bottom: 10px;
    font-weight: bold;

    &-wrapper-icon {
      width: 25px;
      height: 25px;
      margin-right: 5px;

      img {
        width: 100%;
      }
    }
  }

  .avatars {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;

    &-wrapper {
      display: flex;
      flex-wrap: wrap;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      font-weight: bold;

      img {
        margin-bottom: 10px;
        border-radius: 100%;
        box-shadow: 0px 2px 5px 0px rgba(0,0,0,0.15);
      }

      &.active {
        color: $main-txt;

        img {
          border: 2px solid $main-txt;
          
	-webkit-animation: heartbeat 1s both;
	        animation: heartbeat 1s both;
        }
      }
    }

  }
}

.modal-content {
  padding: 25px;

  .custom-button {
    margin-top: 20px;
  }
}

@-webkit-keyframes heartbeat {
  from {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-transform-origin: center center;
            transform-origin: center center;
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
  10% {
    -webkit-transform: scale(0.91);
            transform: scale(0.91);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  17% {
    -webkit-transform: scale(0.98);
            transform: scale(0.98);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
  33% {
    -webkit-transform: scale(0.87);
            transform: scale(0.87);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  45% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
}
@keyframes heartbeat {
  from {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-transform-origin: center center;
            transform-origin: center center;
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
  10% {
    -webkit-transform: scale(0.91);
            transform: scale(0.91);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  17% {
    -webkit-transform: scale(0.98);
            transform: scale(0.98);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
  33% {
    -webkit-transform: scale(0.87);
            transform: scale(0.87);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  45% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
}
