@import 'src/Assets/variables';

.navbar {
  height: $navbar-height;
  background-color: $main;
  position: relative;

  &-logo {
    position: absolute;
    bottom: -41px;

    @media only screen and (max-width: 400px) {
      left: -10px;
    }
  }

  &-logout {
    color: #4F4F4F;
    font-family: 'Poppins-Regular';
    font-weight: bold;
    font-size: 14px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 5%;
    cursor: pointer;
  }
}