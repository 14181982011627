@import 'src/Assets/variables';

.edit-profile {
  &-input-label {
    display: block;
    margin-top: 20px;
    margin-left: 5%;
    font-family: 'Poppins-Regular';
    font-weight: bold;
    font-size: 14px;
    line-height: 10px;

    &-img {
      margin-right: 10px;
    }
  }

  &-input {
    width: 90%;
    margin-left: 5%;
    margin-top: -15px;
  }

  &-banner {
    width: 90%;
    margin-left: 5%;
    box-shadow: 0px 4px 8px rgba(189, 189, 189, 0.1);
    border-radius: 10px;
    padding: 20px 5%;
    margin-top: 40px;
    position: relative;

    &.gold {
      background-color: $secondary;
      color: $white;
    }

    &.grey {
      background-color: $ghost;
      color: #828282;
    }

    &.Hari {
      background-color: $days;
      color: $white;
    }
    &.Days {
      background-color: $days;
      color: $white;
    }
    &.Minggu {
      background-color: $weekly;
      color: $white;
    }
    &.Weekly{
      background-color: $weekly;
      color: $white;
    }
    &.Tahun{
      background-color: $yearly;
      color: $white;
    }
    &.Yearly{
      background-color: $yearly;
      color: $white;
    }

    &-heading {
      display: block;
      font-family: 'Poppins-Regular';
      font-weight: bold;
    }

    &-sub {
      font-size: 14px;
      margin-top: 10px;
    }

    &-btn {
      position: absolute;
      right: 5%;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  &-submit {
    width: 90%;
    margin-left: 5%;
    margin-top: 250px;

    &-btn {
      margin-bottom: 20px;
    }
  }
}