@import 'src/Assets/variables';

.edit-btn {
  display: inline-block;
  width: 30px;
  height: 30px;
  background-color: $main-txt;
  border-radius: 100%;
  color: $white;
  text-align: center;
  padding-top: 4px;
  font-size: 14px;
  cursor: pointer;

  &.disabled {
    background-color: $typography;
    pointer-events: none;
  }
}
