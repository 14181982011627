@import 'src/Assets/variables'; 

.header {
    background-color: $main;
    height: 40vh;
    border-radius: 0px 0px 50% 50%;
    text-align: center;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    &-heading {
        font-family: 'Poppins-Bold';
        font-size: 50px;
        color: $white;
        margin-bottom: 80px;

        &.small {
            font-size: 35px;
        }
    }

    &-img {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        bottom: -20px;
        width: 200px;

        &-arrow {
            position: absolute;
            left: 20px;
            top: 20px;
            width: 35px;
        }
    }
}