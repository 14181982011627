@import 'src/Assets/variables';

.body-content-live-chat {
  border-radius: 15px 15px 0px 0px;
  position: relative;
  z-index: 2;
  top: -13px;
  padding: 15px;
  min-height:80vh;
  padding-top: 130px;
  padding-bottom: 65px;
}


.header-live-chat-fixed {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 9;

  .navbar-live-chat {
    height: $navbar-height;
    background-color: $main;
    position: relative;

    &-logo {
      position: absolute;
      bottom: -41px;

      @media only screen and (max-width: 400px) {
        left: -10px;
      }
    }

    &-logout {
      color: #4F4F4F;
      font-family: 'Poppins-Regular';
      font-weight: bold;
      font-size: 16px;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 5%;
      cursor: pointer;
    }
  }

  .wrapper-header-live-chat {
    background: #fff;
    padding: 15px 15px 0 15px;
    margin-top: -10px;
    position: relative;
    z-index: 10;
    height: 70px;
    border-radius: 10px 10px 0 0;

    .header-live-chat-page {
      background: #FB9D3C;
      position: relative;
      width: 100%;
      height: auto;
      padding: 15px;
      border-radius: 10px 10px 0px 0px;
      color: #fff;
      font-weight: bold;
      align-items: center;
      z-index: 5;
      text-align: center;
      border-bottom: 5px solid #EC851B;
      font-family: 'Poppins-Bold';
      font-size: 18px;
    }
  }

}

.live-chat-page {

  // @media only screen and (min-width: $break-point) {
  //   overflow-y: auto;
  // }
  &-back {
    margin: 0px 0px 15px 25px;
  }

  &-header {

    padding-left: 5%;
    border-bottom: 4px solid rgba(240, 239, 239, 0.8);
    padding-bottom: 30px;

    &-heading {
      display: block;
      font-family: 'Poppins-Bold';
      color: #4F4F4F;
      font-size: 24px;
    }

    &-details {
      margin-top: 20px;

      &-email {
        font-family: 'Poppins-Regular';
        color: $heading;
        font-weight: bold;
        margin-right: 30px;
      }

      &-phone {
        color: $heading;
        display: block;
      }
    }

    &-calendar {
      float: left;
      display: inline;
      font-size: 12px;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;

      img {
        margin-right: 10px;
        height: 15px;
      }
    }
  }

  &-live-chat-doubt {
    margin-top: 20px;
    height: auto;
    background: #5EABD7;
    overflow: scroll;
    position: relative;

    .content-live-chat {
      overflow-y: scroll;
      overflow-x: hidden;
      padding-top: 50px;
      z-index: 1;
      min-height: 80vh;
    }

    .chat {
      font-size: 15px;
      display: flex;
      align-items: flex-start; 
      flex-wrap: wrap;
      padding: 10px;

      .moment {
        font-size: 10px;
        margin-right: 15px;
        margin-left: 15px;
        place-self: flex-end;
        color: #fff;
      }

      &.reserve {
        flex-direction: row-reverse;

        .avatar {
          transform: scaleX(-1);
        }
      }

      .message {
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        text-align: left;
        // flex: 1 1 0%;
        padding: 10px;
        border-radius: 5px;
        background: #fff;
        max-width: 50%;

        h6 {
          display: flex;
          align-items: center;
          font-weight: 700;

          span {
            margin-left: 10px;
            background:#FB9D3C;
            font-size: 12px;
            padding:5px;
            border-radius: 5px;
            color: #fff;
          }
        }
        
        b {
          flex-wrap: wrap;
          align-items: center;
          font-size: 12px;
          margin-bottom: 5px;

          span {
            background-color: $monthly;
            color: #fff;
            font-size: 8px;
            margin-left: 5px;
            padding: 2.5px 5px;
            border-radius: 5px;
          }
        }
      }

      img {
        width: 50px;
        margin-right: 10px;
        margin-left: 10px;
        // transform: scaleX(-1);

        &.avatar {
          width: 60px;
          background: #fff;
          border-radius: 50%;
          padding: 5px;
        }
      }

    }

  }

  &-content {
    margin-top: -40px;

  }

  &-btn {
    width: 90%;
    margin-left: 5%;

    &.add {
      margin-top: 20px;
    }

    &.history {
      margin-top: 20px;
    }
  }

  .ongoing {
    padding: 5px 10px;
    color: #fff;
    background: #86DA4E;
    position: absolute;
    font-size: 12px;
    border-radius: 5px;
    right: 20px;
  }


  .control {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;

    .custom-button {
      width: 48%;

      button {
        width: 100%;
      }
    }
  }
}

.no-chat {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100%;
  font-weight: bold;
  color: #fff;
  font-size: 18px;
  height: 100%;
  min-height: 80vh;
}

.footer-live-chat-wrapper {
  position: fixed;
  z-index: 99;
  bottom: 0;
  width: calc(100vw - 30px);
  padding: 15px ;
  margin: 0 15px ;
  height: 80px;
  background: #f0f0f0;

  .footer-live-chat {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    color: #fff;
    background: #fff;
    border-radius: 25px;

    input {
      flex-grow: 1;
      background-color: #332E2C;
      border: 0;
      color: #fff;
    }

    input:focus {
      border: 0;
      outline: none !important;
    }

    img {
      flex-grow: 0;
      width: 5%;
      margin: 2.5%;
      cursor: pointer;
    }
  }
}


.emoji-wrapper {
  position: fixed;
  bottom: 150px;
}

.option {
  padding: 10px 20px;
  background: #FB9D3C;
  margin-top: 10px;
  cursor: pointer;
  transition: ease-in-out 0.25s;
  color: #fff;
}

.option:hover {
  background: #fc8611;
}

.circle {
  width: 10px;
  height: 10px;
  border-radius: 20px;
  background: #86DA4E;
  border: 1px solid #fff;
  margin-left: 15px;

  &.off {
    background:#4F4F4F;
  }
}



.react-emoji {
  display: flex !important;
  align-items: center !important;
  position: relative !important;
  width: 80% !important;
  flex-direction: row-reverse !important;
  background: #fff !important;
  border-radius: 25px !important;
}

.react-input-emoji--container {
  color: #4b4b4b !important;
  text-rendering: optimizeLegibility !important;
  background-color: #fff !important;
  border: 1px solid #fff !important;
  border-radius: 21px !important;
  margin: 5px 10px !important;
  margin-right: 10px !important;
  box-sizing: border-box !important;
  flex: 1 1 auto !important;
  font-size: 15px !important;
  font-family: sans-serif !important;
  font-weight: 400 !important;
  line-height: 20px !important;
  min-height: 20px !important;
  min-width: 0 !important;
  outline: none !important;
  width: inherit !important;
  will-change: width !important;
  vertical-align: baseline !important;
  border: 0px !important;
  margin-right: 0 !important;
}

.react-input-emoji--button svg {
  display: none !important;
}

.react-input-emoji--button {
  position: relative !important;
  display: block !important;
  text-align: center !important;
  padding: 0 10px !important;
  overflow: hidden !important;
  transition: color 0.1s ease-out !important;
  margin: 0 !important;
  box-shadow: none !important;
  background: url('../../Assets/Images/emoji.png') !important;
  background-size: 25px !important;
  width: 25px !important;
  height: 25px !important;
  margin-left: 10px !important;
  margin-right: -15px !important;
  border: none !important;
  outline: none !important;
  cursor: pointer !important;
  flex-shrink: 0 !important;
}

.react-emoji-picker--wrapper {
  left: 0 !important;
}