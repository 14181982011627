@import 'src/Assets/variables';

.login-page {
  padding-bottom: 40px;

  .paragraph {
    margin: 0 25px;
    margin-top: 30px;
    text-align: center;

    span {
      color: $yearly;
    }
  }

  .renderField {
    margin: 0 25px;
  }

  &-submit {
    // position: absolute;
    bottom: 15px;
  }

  .verifyField {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 200px;

    span {
      max-width: 50%;
    }

    .custom-button {
      max-width: 180px;
    }

    button {
      background-color: $yearly !important;
    }
  }
}

.container-radio {
  display: inline-block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  margin-right: 25px;
  margin-bottom: 50px;
}

.container-radio input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #eee;
  border-radius: 50%;
}

.container-radio:hover input ~ .checkmark {
  background-color: #ccc;
}

.container-radio input:checked ~ .checkmark {
  background-color: $monthly;
}

.checkmark:after {
  content: '';
  position: absolute;
  display: none;
}

.container-radio input:checked ~ .checkmark:after {
  display: block;
}

.container-radio .checkmark:after {
  top: 8px;
  left: 8.5px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: white;
}
