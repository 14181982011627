@import 'src/Assets/variables';

.padding {
  padding: 20px 5% 0 5%;
}

.subscription-button {
  position: absolute;
  bottom: 20px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  padding: 20px 5% 0 5%;
  width: 100%;

  & .custom-button {
    display: flex;
    width: 100%;
    margin-top: 15px;

    & .btn {
      width: 100%;
      padding: 15px 0 15px 0;
    }

    &.secondary {
      background: #fff;
      border: 1px solid $main-txt;
    }
  }
}
