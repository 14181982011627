@import 'src/Assets/variables';
.first-page-container {
  position: relative;
  width: 100%;
  height: auto;
  // background-image: url('https://backend.titikpintar.id/images/blank_1.png');
}

.first-page-background {
  width: 98%;
  height: 98%;
  margin: 1%;
}

.first-page {
  position: absolute;
  top: 0;
  text-align: center;
  margin: 1%;
  width: 98%;
  height: 98%;
  overflow: hidden;
}

.first-page .congrast {
  font-size: 6.5vw;
  margin-top: 20%;
  font-family: Cartwheel;
  color: white;
}

.first-page .we-proudly {
  font-size: 4vw;
  margin-top: 6vw;
  font-family: Cartwheel;
  color: white;
}

.first-page .name {
  font-size: 7vw;
  margin-top: 3%;
  font-family: Cartwheel;
  color: white;
}

.first-page table {
  width: 82%;
  margin: 3% 9%;
  margin-bottom: 4%;
}

.first-page .name-header {
  font-size: 2vw;
  width: 39%;
  font-family: Cartwheel;
  color: white;
  padding-top: 2.5vw;
}

.first-page .correct-header {
  font-size: 2vw;
  width: 18%;
  font-family: Cartwheel;
  color: white;
  padding-top: 2.5vw;
}

.first-page .incorrect-header {
  font-size: 2vw;
  width: 18%;
  font-family: Cartwheel;
  color: white;
  padding-top: 2.5vw;
}

.first-page .time-header {
  font-size: 2vw;
  font-family: Cartwheel;
  color: white;
  padding-top: 2.5vw;
}

.first-page .content {
  font-size: 1.5vw;
  height: 6vw;
  font-family: Cartwheel;
  vertical-align: middle;
}

.first-page .fun-facts {
  font-size: 2vw;
  font-family: Cartwheel;
  color: white;
  vertical-align: middle;
}

.first-page .ff-desc {
  font-size: 1.5vw;
  font-family: Cartwheel;
  vertical-align: middle;
  margin: 0 30vw;
  margin-top: 4vw;
}

.second-page-container {
  position: relative;
  width: 100%;
  height: auto;
}

.second-page-background {
  width: 98%;
  height: 98%;
  margin: 1%;
}

.second-page {
  position: absolute;
  top: 0;
  text-align: center;
  margin: 1%;
  width: 98%;
  height: 98%;
  overflow: hidden;
}

.second-page .leaderboard {
  font-size: 5vw;
  margin-top: 7.5%;
  margin-bottom: 6%;
  font-family: Cartwheel;
  color: white;
}

.second-page.middle .leaderboard {
  margin-bottom: 3%;
}

.second-page.last .leaderboard {
  margin-bottom: 4.5%;
}

.second-page .rank {
  width: 60%;
  margin: 0 20%;
  margin-bottom: 2vw;

  color: #333;
  text-align: left;

  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.second-page .rank.first-1 {
  opacity: 1;
}

.second-page .rank.first-2 {
  opacity: 0.7;
}

.second-page .rank.first-3 {
  opacity: 0.5;
}

.second-page.middle .rank.middle-1 {
  opacity: 0.7;
}

.second-page.middle .rank.middle-2 {
  opacity: 1;
  margin-top: 5vw;
}

.second-page.middle .rank.middle-3 {
  opacity: 0.5;
}

.second-page.last .rank.last-1 {
  opacity: 0.5;
}

.second-page.last .rank.last-2 {
  opacity: 0.7;
}

.second-page.last .rank.last-3 {
  opacity: 1;
  margin-top: 4vw;
  margin-bottom: 7vw;
}

.second-page .rank.own {
  color: white;
  margin: 0 15%;
  width: 70%;
  margin-bottom: 5vw;
}

.second-page .rank .rank-sub {
  display: inline-block;
}

.second-page .rank .rank-sub.num {
  font-size: 5vw;
  margin-right: 2vw;
  font-family: Cartwheel;
}

.second-page .rank.own .rank-sub.num {
  margin-right: 5vw;
}

.second-page .rank .rank-sub.avatar img {
  width: 6vw;
}

.second-page .rank .rank-sub.name {
  margin-left: 2vw;
  font-size: 3vw;
  font-family: Cartwheel;
}

.second-page .rank.own .rank-sub.name {
  margin-left: 4vw;
}

.second-page .rank .rank-sub.mp {
  float: right;
  font-size: 3vw;
  font-family: Cartwheel;
  position: absolute;
  right: 22.5vw;
}

.second-page .rank.own .rank-sub.mp {
  right: 20vw;
}

.second-page.last .rank.own .rank-sub.mp {
  right: 18vw;
}

.second-page .have {
  font-size: 3vw;
  margin-top: 5%;
  margin-bottom: 6%;
  font-family: Cartwheel;
  color: white;
}

.second-page .stats {
  width: 81vw;
  margin: 8vw 8.5vw;
}

.second-page .stats .box {
  display: inline-block;
  width: 21vw;
  margin: 0 3vw;
  font-family: Cartwheel;
  color: white;
  font-size: 2vw;
  padding-top: 5vw;
}

.second-page .stats .box .count {
  font-size: 8vw;
  margin-top: 7vw;
}

.second-page .plan {
  width: 81vw;
  margin: 8vw 8.5vw;
  font-family: Cartwheel;
  margin-bottom: 3vw;
}

.second-page .plan .plan-box {
  display: inline-block;
}

.second-page .plan .plan-box.plan-desc {
  color: white;
  width: 40vw;
  text-align: left;
  vertical-align: top;
  font-size: 3vw;
  padding: 6vw 7vw;
  float: left;
}

.second-page .plan .plan-box.plan-price {
  color: $monthly;
  width: 38vw;
  text-align: center;
  padding-left: 4vw;
}

.second-page .plan .plan-box.plan-price .pintar-plan {
  font-size: 4vw;
}

.second-page .plan .plan-box.plan-price .plan-name {
  font-size: 2vw;
}

.second-page .plan .plan-box.plan-price .prev-price {
  font-size: 2vw;
  color: #333;
}

.second-page .plan .plan-box.plan-price .prev-price .strikeout {
  position: relative;
}

.second-page .plan .plan-box.plan-price .prev-price .strikeout::after {
  border-bottom: 0.125em solid #333;
  content: '';
  left: -10%;
  width: 120%;
  margin-top: calc(0.125em / 2 * -1);
  position: absolute;
  right: 0;
  top: 50%;
  transform: rotate(-5deg);
}

.second-page .plan .plan-box.plan-price .price {
  font-size: 6vw;
}

.second-page .sosial {
  width: 100vw;
  font-family: Cartwheel;
  margin-bottom: 2vw;
  color: white;
  text-align: left;
  position: absolute;
  bottom: 0;
}

.second-page .sosial .sosial-box {
  display: inline-block;
  font-size: 1.5vw;
}

.second-page .sosial .sosial-box.web {
  margin-left: 12vw;
}

.second-page .sosial .sosial-box.fb {
  margin-left: 7vw;
}

.second-page .sosial .sosial-box.yt {
  margin-left: 19vw;
}

.second-page .sosial .sosial-box.ig {
  margin-left: 15vw;
}
